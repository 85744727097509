import React from 'react';
import { Link } from 'react-router-dom';
import { logo, map } from '../assets/img/img';

const Footer = () => {

  return (
    <footer className="footer-area" style={{
      backgroundImage: `url(${map})`, // Use the imported logo
     
    }}>
      <div className="container">
        <div className="row">
          {/* Logo and Brief */}
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to="/">
                  <img
                    alt="logo"
                    loading="lazy"
                    width="200"
                    decoding="async"
                    data-nimg="1"
                    style={{ color: 'transparent' }}
                    src={logo}
                  />
                </Link>
              </div>
              <p>
                We have a dedicated team of software developers and..
                <Link className="read-more-btn" to="/about">
                  Read More
                </Link>
              </p>
            </div>
          </div>
          {/* Company Links */}
          <div className="col-lg-3 col-md-6 col-6">
            <div className="single-footer-widget">
              <h3>Company</h3>
              <ul className="list">
                <li>
                  <Link to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/service">
                    Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* Support Links */}
          <div className="col-lg-3 col-md-6 col-6">
            <div className="single-footer-widget">
              <h3>Support</h3>
              <ul className="list">
                <li>
                  <Link to="/privacy-poclicy">Privacy Policy</Link>
                </li>

                <li>
                <Link  to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* Address and Social Links */}
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>Address</h3>
              <ul className="footer-contact-info">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </svg>
                  25 A Shree Krishana Nagar, Opp. BSF, Airport Road, Indore, Madhya Pradesh, 452005, India
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                  Email: <Link to="mailto:aadunikvigyan.com@gmail.com">aadunikvigyan.com@gmail.com</Link>
                </li>
              </ul>
              <ul className="social-links">
                <li>
                  <Link to="https://www.facebook.com/aadhunik.vigyan/" className="facebook" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                    </svg>
                  </Link>
                </li>
                {/* <li>
                  <Link to="https://www.facebook.com/aadhunik.vigyan/" className="twitter" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                    </svg>
                  </Link>
                </li> */}
                <li>
                  <Link to="https://www.instagram.com/aadhunik_vigyan/" className="instagram" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                      <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/in/aadhunik-vigyan-43aa101b2" className="linkedin" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <p>
          Copyright © 2025. All rights reserved by &nbsp;
           <Link to="/" target="_blank">
              Aadhunikvigyan
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
