import React, { useEffect, useState } from 'react';
import { contact_banner } from '../assets/img/img';

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page reload

    // You can validate the form here if needed (e.g., check if fields are filled)
    if (formData.name && formData.email && formData.subject && formData.message) {
      // Show the thank you message upon successful form submission
      setSubmitted(true);

      // Optionally, reset form fields after 2 seconds
      setTimeout(() => {
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
        setSubmitted(false); // Reset thank you message after form reset
      }, 2000);
    }
  };
  return (
    <div className="contact-area ptb-80">
      <div className="container">
        <div className="section-title">
          <h2>Get In Touch With Us</h2>
          <div className="bar"></div>
          <p>Anything on your mind? We’ll be glad to assist you!</p>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <img
              alt="Contact Us"
              loading="lazy"
              className="img-fluid"
              src={contact_banner}
              style={{ color: "transparent" }}
            />
          </div>
          <div className="col-lg-6 col-md-12">
            {!submitted ? (
              <form onSubmit={handleSubmit} id="contactForm">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="name" className="sr-only">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        className="form-control"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="email" className="sr-only">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Your Email Address"
                        className="form-control"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="subject" className="sr-only">Subject</label>
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        placeholder="Your Subject"
                        className="form-control"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="message" className="sr-only">Message</label>
                      <textarea
                        id="message"
                        name="message"
                        cols="50"
                        rows="6"
                        placeholder="Write your message..."
                        className="form-control"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 pb-5">
                    <button type="submit" className="btn btn-primary">Send Message</button>
                  </div>
                </div>
              </form>
            ) : (
              <div id="thankYouMessage">
                <h3>Thank You for contacting us! We will get back to you soon.</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact