import React, { useEffect, useState } from 'react';
import { home_banner, it_service, money, support, thums_up } from '../assets/img/img';
import { Link } from 'react-router-dom';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    const [currentSlide, setCurrentSlide] = useState(0);

    const testimonials = [
        {
            text: "Real nice design and fantastic subject matter, practically nothing else we require.",
            author: "Nikhil",
        },
        {
            text: "This is the very first time I frequented your web page and thus far? I amazed with the research you made to create this particular publish incredible. Wonderful job!",
            author: "Himanshu Chauhan",
        },
        {
            text: "Since the member of this website is working, no question very quickly it will be well-known, due to its quality contents.",
            author: "Deepak Sharma",
        },
    ];

    const totalSlides = testimonials.length;

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % totalSlides);
    };

    const prevSlide = () => {
        setCurrentSlide((currentSlide - 1 + totalSlides) % totalSlides);
    };

    const selectSlide = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div>
            <div className="contact-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>About Us</h2>
                        <div className="bar"></div>
                        <p>Anything On your Mind? We’ll Be Glad To Assist You!</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 home-img">
                            <img
                                className="img-fluid"
                                src={home_banner}
                                alt="About Us"
                            />
                        </div>
                        <div className="col-lg-6 col-md-12 mt-5">
                            <p>
                                We have a dedicated team of web developers and designers who
                                are passionate about their work. We help startups and those
                                wanting to establish an online presence through web and
                                application development.
                            </p>
                            <p>
                                We specialize in WordPress Development, Web Designing, Landing
                                Pages, E-Commerce Websites, Content Writing, and more.
                            </p>
                            <p>
                                Our technology stack includes HTML5, CSS3, JavaScript,
                                Bootstrap, React, Angular, PHP, WordPress, Codeigniter, Node.js,
                                and more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-f7fafd">
                <div className="boxes-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-6">
                                <div className="single-box">
                                    <div className="icon">
                                        <img src={support} alt="Great Support" />
                                    </div>
                                    <h3>Great Support</h3>
                                    <p>
                                        Empowering your journey with exceptional service and
                                        unwavering support.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-box">
                                    <div className="icon">
                                        <img src={money} alt="Affordable Price" />
                                    </div>
                                    <h3>Affordable Price</h3>
                                    <p>
                                        Quality you can trust at a price you can afford—your
                                        satisfaction guaranteed.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-box">
                                    <div className="icon">
                                        <img src={thums_up} alt="Availability" />
                                    </div>
                                    <h3>Availability</h3>
                                    <p>
                                        Always here when you need us, ensuring reliable service
                                        around the clock.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-box">
                                    <div className="icon">
                                        <img src={it_service} alt="IT Consulting" />
                                    </div>
                                    <h3>IT Consulting</h3>
                                    <p>
                                        Strategic IT solutions tailored to drive your success and
                                        innovation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="section-title">
                        <h2>Our Testimonials</h2>
                        <div className="bar"></div>
                    </div>
                    <div className="container slideshow-container">
                        <div className="mySlides">
                            <q>{testimonials[currentSlide].text}</q>
                            <p className="author">~ {testimonials[currentSlide].author}</p>
                        </div>
                        <Link className="prev" onClick={prevSlide}>
                            ❮
                        </Link>
                        <Link className="next" onClick={nextSlide}>
                            ❯
                        </Link>
                    </div>
                    <div className="dot-container">
                        {testimonials.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentSlide ? "active" : ""}`}
                                onClick={() => selectSlide(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About