import React, { useEffect } from "react";
import { cms_development, content, domain_hosting, e_commerce, web_design, web_development } from "../assets/img/img";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <div className="solutions-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>Services</h2>
            <div className="bar"></div>
            {/* <p>Anything On your Mind. We’ll Be Glad To Assist You!</p> */}
          </div>
        </div>
      </div>

      <section className="service-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={web_design} className="img-fluid" alt="Web Design" />
            </div>
            <div className="col-md-6 mt-5">
              <h3>Web Design</h3>
              <p>
                We provide attractive and responsive web design with mobile optimization. A web designer’s responsibility
                is to construct the overall look of a website, using images, HTML, CSS, and JavaScript. Web Designers
                are typically creative in nature, selecting aesthetically pleasing designs for web projects.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-f7fafd mt-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-6">
              <h3 className="pt-5">CMS Development</h3>
              <p>
                CMS (Content Management System) based websites enable even non-technical users to efficiently manage
                and make changes to the website easily.
              </p>
            </div>
            <div className="col-md-6">
              <img src={cms_development} className="img-fluid" alt="CMS Development" />
            </div>
          </div>
        </div>
      </section>

      <section className="service-sec me-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={web_development} className="img-fluid" alt="Web Development" />
            </div>
            <div className="col-md-6 mt-5">
              <h3>Web Development</h3>
              <p>
                We provide attractive and responsive web design with mobile optimization. A web designer’s responsibility
                is to construct the overall look of a website, using images, HTML, CSS, and JavaScript. Web Designers
                are typically creative in nature, selecting aesthetically pleasing designs for web projects.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-f7fafd mt-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-6">
              <h3 className="pt-5">E-commerce Web Development</h3>
              <p>
                We provide an environment for selling products on your own site and develop SEO-friendly websites. Our
                services include eCommerce shopping cart development and secure payment gateway integration. We ensure
                that e-commerce website designs attract potential customers, provide great user experience, and present
                your shop in the best light.
              </p>
            </div>
            <div className="col-md-6">
              <img src={e_commerce} className="img-fluid" alt="E-commerce Web Development" />
            </div>
          </div>
        </div>
      </section>

      <section className="service-sec my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={content} className="img-fluid" alt="Content Writing" />
            </div>
            <div className="col-md-6 mt-5">
              <h3>Content Writing</h3>
              <p>
                We create text-based content in a variety of fields including Fashion, Medicine, and Technology. Our
                services include working with customers to define their content needs, conducting research on given
                topics, and creating written content for websites and other types of media.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-f7fafd mt-3">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-6">
              <h3 className="pt-5">Domain and Hosting</h3>
              <p>
                Domain and hosting are the most important parts of creating a website. It is crucial to understand both
                services, as they work together to make websites possible. Every website you’ve ever visited is hosted
                on a server.
              </p>
            </div>
            <div className="col-md-6">
              <img src={domain_hosting} className="img-fluid" alt="Domain and Hosting" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
